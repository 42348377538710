<template>
  <div>
    <v-data-table :items="events" :headers="headers" > </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'ChargebackAnalyticsEthocaTable',
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Alert Type', value: 'alert_type' },
        { text: 'Ethoca ID', value: 'alert_id' },
        { text: 'Alert Date/Time', value: 'alert_timestamp' },
        { text: 'Alert Age (Hours)', value: 'age' },
        { text: 'Auth Date/Time', value: 'auth_date' },
        { text: 'Auth Code', value: 'auth_code' },
        { text: 'Amount', value: 'amount' },
        { text: 'Currency', value: 'currency' },
        { text: 'Card Number', value: 'card_number' },
        { text: 'SHA1 Hashed Card Number', value: 'hashed_card_number' },
        { text: 'Merchant', value: 'merchant_name' },
        { text: 'Merchant Descriptor/MID', value: 'descriptor' },
        { text: 'Issuer Name', value: 'issuer_name' },
        { text: 'Source', value: 'source' },
        { text: 'Transaction Type', value: 'transaction_type' },
        { text: 'Outcome', value: 'outcome' },
        { text: 'Refunded/Not Settled', value: 'refunded' },
        { text: 'Partially Stopped Amount', value: 'partially_stopped_amount' },
        { text: 'Comments', value: 'comments' },
        { text: 'Existing Outcome', value: 'existing_outcome' },
        { text: 'Existing Comments', value: 'existing_comments' },
        { text: 'Outcome Updated Date/Time', value: 'outcome_updated_date' },
        { text: 'Outcome Updated By', value: 'outcome_updated_by' },
        { text: 'ARN', value: 'arn' },
        { text: 'Transaction ID', value: 'transaction_id' },
        { text: 'Chargeback Reason Code', value: 'chargeback_reason_code' },
        { text: 'Chargeback Amount', value: 'chargeback_amount' },
        { text: 'Chargeback Currency', value: 'chargeback_currency' },
        { text: 'BIN8', value: 'bin8' },
      ],
    };
  },
};
</script>
