<template>
  <div>
    <v-data-table :items="events" :headers="headers" > </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'ChargebackAnalyticsVerifiTable',
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Case ID', value: 'case_id' },
        { text: 'Case Received Date', value: 'alert_timestamp' },
        { text: 'Status', value: 'status' },
        { text: 'Status Code', value: 'status_code' },
        { text: 'Case Currency Code', value: 'currency' },
        { text: 'Case Amount', value: 'amount' },
        { text: 'Descriptor/SE Number', value: 'descriptor' },
        { text: 'Descriptor Contact', value: 'descriptor_contact' },
        { text: 'Case Age', value: 'age' },
        { text: 'Merchant Name', value: 'merchant_name' },
      ],
    };
  },
};
</script>
