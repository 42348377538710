import { ref, watch } from '@vue/composition-api';
import store from '@/store';

export default function useChargebackAnalytics() {

  const services = [
    {
      title: 'Ethoca',
      value: 'ethocaService'
    },
    {
      title: 'Verifi',
      value: 'verifiService'
    }
  ];
  const uploadedFile = ref(null);
  const uploadedFileAnalytics = ref(false);
  const uploadedFileAnalyticsResult = ref(null);
  const uploadedFileAnalyticsError = ref(null);
  const selectedProvider = ref(null);

  const exportAnalytics = (eventType) => {
     const csvData = convertToCSV(uploadedFileAnalyticsResult.value[eventType]);
     downloadFile(csvData, eventType+'_analytics.csv', 'text/csv');

  };

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(',') + '\n';
    const csvLines = data.map(row => Object.values(row).join(',')).join('\n');
    return header + csvLines;
  };

  const downloadFile = (data, fileName, type) => {
    //Fix for Safari downloading csv
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(
      new Blob([data], { type })
    );
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  watch(uploadedFile, () => {
    if (!uploadedFile.value || !selectedProvider.value) {
      return;
    }

    uploadedFileAnalytics.value = true;
    uploadedFileAnalyticsResult.value = null;
    uploadedFileAnalyticsError.value = null;

    let formData = new FormData();
    formData.append('file', uploadedFile.value);
    formData.append('service', selectedProvider.value);

    store
      .dispatch('chargebackEvent/eventAnalytics', formData)
      .then(response => {
        uploadedFileAnalyticsResult.value = response.data;
      })
      .catch(() => {
        uploadedFileAnalyticsError.value = 'Can not parse file';
      })
      .finally(() => {
        uploadedFileAnalytics.value = false;
        uploadedFile.value = null;
      });
  });
  return {
    services,
    uploadedFile,
    selectedProvider,
    uploadedFileAnalytics,
    uploadedFileAnalyticsResult,
    uploadedFileAnalyticsError,
    exportAnalytics
  };
}
