<template>
  <div id="chargeback-analytics">
    <v-card>
      <v-card-title>
        Event Analytics
      </v-card-title>
      <v-row  class="px-2 ma-0">
        <v-col cols="12" sm="4">
          <v-select
            class="chargeback-event-provider"
            v-model="selectedProvider"
            placeholder="Select provider"
            :items="services"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">

          <v-file-input
            v-model="uploadedFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            label="Select a file..."
            :loading="uploadedFileAnalytics"
            :disabled="uploadedFileAnalytics"
            dense
            outlined
          ></v-file-input>
        </v-col>
        <v-col v-if="uploadedFileAnalyticsError" cols="12" sm="12">
          <v-alert color="error" dark>
            {{ uploadedFileAnalyticsError }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="px-2 ma-0" v-if="uploadedFileAnalyticsResult">
        <v-divider class="mt-4 mb-4"></v-divider>
        <v-col cols="12" sm="12" v-if="uploadedFileAnalyticsResult.ethoca.length">
          <v-row>
            <v-col cols="6" sm="6">
              Events missing in Merchanto
            </v-col>
            <v-col cols="6" sm="6" class="text-right">
              <v-btn color="primary" class="me-3 export-analytics-ethoca" @click="exportAnalytics('ethoca')"> Export to CSV </v-btn>
            </v-col>
            <v-col cols="12" sm="12">
              <ChargebackAnalyticsEthocaTable :events="uploadedFileAnalyticsResult.ethoca"/>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" v-if="uploadedFileAnalyticsResult.verifi.length">
          <v-row>
            <v-col cols="6" sm="6">
              Events missing in Merchanto
            </v-col>
            <v-col cols="6" sm="6" class="text-right">
              <v-btn color="primary" class="me-3 export-analytics-verifi" @click="exportAnalytics('verifi')"> Export to CSV </v-btn>
            </v-col>
            <v-col cols="12" sm="12">
              <ChargebackAnalyticsVerifiTable :events="uploadedFileAnalyticsResult.verifi"/>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" v-if="!uploadedFileAnalyticsResult.verifi.length && !uploadedFileAnalyticsResult.ethoca.length">
          <v-alert color="success" dark>
            All events match
          </v-alert>
        </v-col>

      </v-row>
    </v-card>
  </div>
</template>

<script>
import useChargebackAnalytics from '@/views/analytics/chargeback-analytics/useChargebackAnalytics';
import ChargebackAnalyticsEthocaTable from './components/ChargebackAnalyticsEthocaTable';
import ChargebackAnalyticsVerifiTable from './components/ChargebackAnalyticsVerifiTable';
export default {
  name: 'ChargebackAnalytics',
  components: { ChargebackAnalyticsEthocaTable, ChargebackAnalyticsVerifiTable },
  setup() {
    const {
      services,
      uploadedFile,
      uploadedFileAnalytics,
      uploadedFileAnalyticsResult,
      uploadedFileAnalyticsError,
      selectedProvider,
      exportAnalytics,
    } = useChargebackAnalytics();

    return {
      services,
      uploadedFile,
      uploadedFileAnalytics,
      uploadedFileAnalyticsResult,
      uploadedFileAnalyticsError,
      selectedProvider,
      exportAnalytics,
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
